export const ENGLISH_TITLE = {
  Company: 'Company',
  Philosophy: 'Philosophy',
  History: 'History',
  CEOMessage: 'CEO Message',
  OurStrengths: 'Our Strengths',
  GroupCompanies: 'Group Companies',
  Outline: 'Outline',
  Organization: 'Organization',
  Service: 'Service',
  HumanResource: 'Human Resource',
  Outsourcing: 'Outsourcing',
  SystemSolution: 'System Solution',
  MandA: 'M&A',
  Financial: 'Financial',
  Sustainability: 'Sustainability',
  IR: 'IR',
  ManagementPolicy: 'Management Policy',
  // CEOMessage:"CEO Message",
  // Philosophy:"Philosophy"
  // Our Strengths
  DisclosurePolicy: 'Disclosure Policy',
  CorporateGovernance: 'Corporate Governance',
  Finance: 'Finance',
  FinancialHighlights: 'Financial Highlights',
  FinancialPosition: 'Financial Position',
  CashFlow: 'Cash Flow',
  IRLibrary: 'IR Library',
  FinancialStatementReports: 'Financial Statement Reports',
  FinancialResultsBriefing: 'Financial Results Briefing',
  AnnualReports: 'Annual Reports',
  BusinessReport: 'Business Report',
  Disclosure: 'Disclosure',
  OtherMaterials: 'Other Materials',
  Stock: 'Stock',
  StockInformation: 'Stock Information',
  ShareholdersMeeting: "Shareholders' Meeting",
  ArticlesOfIncorporationAndRegulations:
    'Articles Of Incorporation And Regulations',
  ProceduresForStockRelatedMatters: 'Procedures For Stock-related Matters',
  IRNews: 'IR News',
  IndividualInvestor: 'Individual Investor',
  FAQ: 'FAQ',
  IRSchedule: 'IR Schedule',
  PublicNotice: 'Public Notice',
  Disclaimer: 'Disclaimer',
  Recruit: 'Recruit',
  News: 'News',
  Contact: 'Contact',
  PrivacyPolicy: 'Privacy Policy',
  Policy: 'Policy',
  Sitemap: 'Sitemap',
  externalTransmission: 'External Transmission',
} as const
