import { css } from '@emotion/react'
import { colorList } from './color'

export const redArrow = css`
        a.redArrow {
            position: relative;
            &::after {
              content: '';
              position: absolute;
              top: 50%;
              right: -16px;
              transform: translateY(-50%);
              background-color: ${colorList.primary};
              width: 9px;
              height: 6px;
              clip-path: polygon(0 0, 0% 100%, 100% 50%);
            }
        }

        @media screen and (min-width: 1199px) {
            
        }
        
    `