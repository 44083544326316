import React, { ReactNode } from 'react'
import { MvChildPage } from '../Mv/MvChildPage'
import { layoutList } from '../../styles/layout'
import { LayoutRoot } from './LayoutRoot'

interface Props {
  children: ReactNode
  heading: string
  headingEnglish: string
  BreadcrumbData: Array<{ title: string; url: string }>
}

/**
 * 下層ページのレイアウト、サイドナビなし
 * @param {ReactNode} children
 * @param {string} heading
 * @param {string} headingEnglish
 * @param {Array} BreadcrumbData
 */
/** */
export const LayoutNoSideNav = ({
  children,
  heading,
  BreadcrumbData,
  headingEnglish,
}: Props) => {
  return (
    <LayoutRoot>
      <MvChildPage
        heading={heading}
        headingEnglish={headingEnglish}
        BreadcrumbData={BreadcrumbData}
      />
      <div
        css={layoutList.layoutInner}
        className="flex justify-center flex-col pc:flex-row pc:items-start"
      >
        <div className="pc:w-[800px] pb-14 relative">{children}</div>
      </div>
    </LayoutRoot>
  )
}
