import React from 'react'

interface Props {
  title: string
  url: string
}

/**
 * メタタグの雛形
 * urlは / から始まる絶対パス
 * @param {string} title
 * @param {string} url
 */
/** */
export const SEO = ({ title, url }: Props) => {
  return (
    <>
      <title>{`${title} | 総合人材サービスのCRGホールディングス`}</title>
      <meta
        name="keywords"
        content={`${title},人材派遣,総合アウトソーシング,CRGホールディングス`}
      />
      <meta
        name="description"
        content={`CRGホールディングスの「${title}」について。CRGホールディングスは「成長を愉しもう。」の理念を掲げ、『働くことの幸せ』をより多くの人に届ける事を目指す総合人材サービス・アウトソーシング企業です。`}
      />
      <meta
        property="og:title"
        content={`${title} | 総合人材サービスのCRGホールディングス`}
      />

      <meta property="og:type" content="article" />

      <meta property="og:url" content={`https://www.crgh.co.jp${url}`} />
      <meta
        property="og:image"
        content="https://www.crgh.co.jp/common/img/og_image.png"
      />
      <meta property="og:site_name" content="CRGホールディングス" />
      <meta
        property="og:description"
        content={`CRGホールディングスの「${title}」について。CRGホールディングスは「成長を愉しもう。」の理念を掲げ、『働くことの幸せ』をより多くの人に届ける事を目指す総合人材サービス・アウトソーシング企業です。`}
      />
      {/* 20230926 追加 */}
      <meta
        name="facebook-domain-verification"
        content="ty5782t7m4xyz5t30mnss9u10t9lgh"
      />
    </>
  )
}

/**
 * メタタグの雛形、IR用
 * urlは / から始まる絶対パス
 * @param {string} title
 * @param {string} url
 */
/** */
export const SEOIR = ({ title, url }: Props) => {
  return (
    <>
      <title>{`${title} | 株主・投資家の皆様へ | 総合人材サービスのCRGホールディングス`}</title>
      <meta
        name="keywords"
        content={`${title},IR,人材派遣,総合アウトソーシング,CRGホールディングス`}
      />
      <meta
        name="description"
        content={`株主・投資家の皆様へ。CRGホールディングスの「${title}」をご覧いただけます。`}
      />
      <meta
        property="og:title"
        content={`${title} | 株主・投資家の皆様へ | 総合人材サービスのCRGホールディングス`}
      />

      <meta property="og:type" content="article" />

      <meta property="og:url" content={`https://www.crgh.co.jp${url}`} />
      <meta
        property="og:image"
        content="https://www.crgh.co.jp/common/img/og_image.png"
      />
      <meta property="og:site_name" content="CRGホールディングス" />
      <meta
        property="og:description"
        content={`株主・投資家の皆様へ。CRGホールディングスの「${title}」をご覧いただけます。`}
      />
      {/* 20230926 追加 */}
      <meta
        name="facebook-domain-verification"
        content="ty5782t7m4xyz5t30mnss9u10t9lgh"
      />
    </>
  )
}
