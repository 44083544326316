import React from 'react'
import { HeadingSecondary } from '../../atoms/Heading/HeadingSecondary'
import { layoutList } from '../../styles/layout'
import { Breadcrumb } from '../../molecules/Breadcrumb/Breadcrumb'
import { MvBgImg } from './MvBgImg'

interface Props {
  heading: string
  headingEnglish: string
  BreadcrumbData: Array<{ title: string; url: string }>
}

/**
 * 下層ページのファーストビュー
 */
/** */
export const MvChildPage = ({
  heading,
  headingEnglish,
  BreadcrumbData,
}: Props) => {
  return (
    <>
      <MvBgImg />
      <div className="pb-14" css={layoutList.layoutInner}>
        <div className="pt-[88px] pc:pt-[96px] pb-10 pc:pb-12">
          <HeadingSecondary label={heading} EnglishLabel={headingEnglish} />
        </div>
        <Breadcrumb dataList={BreadcrumbData} />
      </div>
    </>
  )
}
